<template>
  <footer>
    <v-row justify="center" class="socials pt-2">
      <v-col
        align-self="center"
        justify="center"
        cols="12"
        md="3"
        class="text-body-1 text-center"
      >
        Follow Us On:
      </v-col>
      <v-col cols="auto">
        <v-row justify="space-between">
          <v-col
            :cols="2"
            v-for="(s, i) in socials"
            :key="i"
            class="text-center"
          >
            <a :href="s.url">
              <v-avatar :color="s.color" size="36px">
                <v-icon color="white" class="pa-1">
                  {{ s.icon }}
                </v-icon>
              </v-avatar>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto" class="">
        <span class="font-weight-light caption">
          &copy; {{ new Date().getFullYear() }} UNIQUE BIT TECHNOLOGIES PVT. LTD.
          All Rights Reserved.
        </span>
      </v-col>
    </v-row>
  </footer>
</template>
<script>
import {
  mdiLinkedin,
  mdiTwitter,
  mdiFacebook,
  mdiInstagram,
  mdiYoutube,
} from '@mdi/js'
export default {
  data() {
    return {
      socials: [
        {
          icon: mdiLinkedin,
          url: 'https://www.linkedin.com/company/codedrills/',
          color: '#0077b5',
        },
        {
          icon: mdiTwitter,
          url: 'https://twitter.com/CodeDrills',
          color: '#1DA1F2',
        },
        {
          icon: mdiFacebook,
          url: 'https://www.facebook.com/CodeDrills-102324484754529',
          color: '#1778F2',
        },
        {
          icon: mdiYoutube,
          url: 'https://www.youtube.com/channel/UC_VWpByh3ZQtpTV0e4MiNxQ',
          color: '#FF0000',
        },
        {
          icon: mdiInstagram,
          url: 'https://www.instagram.com/codedrills/',
          color: '#833AB4',
        },
      ],
    }
  },
}
</script>
<style scoped>
footer {
  background: #165fd8;
}
.socials {
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 12rem;
}
</style>
