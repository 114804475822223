<template>
  <div
    :class="[
      $vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4',
      'py-16',
    ]"
  >
    <div class="text-h3 text-center">
      What our customers are saying
      <div class="rev__title__underline"></div>
    </div>
    <v-row justify="center" class="py-16">
      <v-col
        :cols="10"
        :md="4"
        v-for="(rev, i) in reviews"
        :key="i"
        :class="[$vuetify.breakpoint.lgAndUp ? 'px-16' : '']"
      >
        <v-card class="rounded-tr-xl rounded-tl-xl rounded-br-xl pa-6">
          <v-card-text class="black--text">
            {{ rev.text }}
            <div class="ratings pt-3">
              <v-icon
                :color="r <= rev.stars ? '#165FD8' : '#165FD880'"
                v-for="r in 5"
                :key="`${r}${rev.reviewer.name}`"
              >
                {{ mdiStar }}
              </v-icon>
            </div>
          </v-card-text>
          <!-- <v-card-actions> </v-card-actions> -->
        </v-card>
        <v-row class="white--text my-6 py-6">
          <v-col cols="4">
            <v-avatar size="56px" class="circular__border">
              <v-img :src="rev.image"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="8">
            <div style="color: #165fd8" class="text-h6">
              {{ rev.reviewer.name }}
            </div>
            <div class="text-body-2">{{ rev.reviewer.position }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiStar } from '@mdi/js'

export default {
  data() {
    return {
      mdiStar,
      reviews: [
        {
          image: 'https://storage.googleapis.com/frontend-common/pp.jpeg',
          stars: 5,
          text: "Using CodeDrills, we set up our team of data scientists quickly. We used the customised tests to assess the candidate's skills accurately and save time.",
          reviewer: {
            name: 'Pritam Thakur',
            position: 'Co-Founder, Ivy Homes',
          },
        },
      ],
    }
  },
}
</script>
<style scoped>
.circular__border {
  outline: 6px solid white;
}
.v-card__actions {
  background-color: transparent;
}
.v-card {
  box-shadow: 0px 2px 29px rgba(143, 189, 204, 0.29);
}
.v-card::after {
  content: '';
  position: absolute;
  visibility: visible;
  bottom: -40px;
  left: 0px;
  border-right: 40px solid transparent;
  border-top: 45px solid #fff;
  clear: both;
}
.rev__title__underline {
  border-bottom: 6px solid #165fd8;
  width: 15%;
  padding-top: 16px;
  margin-left: 22%;
}
</style>
