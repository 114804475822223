<template>
  <main class="white--text">
    <NavCampaign />
    <Hero />
    <Pricing />
    <Features />
    <Customers />
    <SignupCard />
    <Footer />
  </main>
</template>
<script>
import { mdiConsoleLine } from '@mdi/js'
import Hero from '../../components/campaign/Hero.vue'
import Footer from '../../components/campaign/Footer.vue'
import Pricing from '../../components/campaign/Pricing.vue'
import Features from '../../components/campaign/Features.vue'
import Customers from '../../components/campaign/Customers.vue'
import SignupCard from '../../components/campaign/SignupCard.vue'
import NavCampaign from '../../components/campaign/NavCampaign.vue'

export default {
  components: {
    Hero,
    Pricing,
    Features,
    Customers,
    SignupCard,
    Footer,
    NavCampaign,
  },
  data() {
    return {
      mdiConsoleLine,
      drawer: false,
      menuItems: [
        {
          title: 'Start Hiring',
          path: '/try',
        },
        {
          title: 'Contact Us',
          path: '/try',
        },
      ],
    }
  },
}
</script>
<style scoped>
main {
  background-color: #202531;
}
</style>
<style>
.try__padded__container {
  padding-left: 5rem;
  padding-right: 5rem;
}
</style>
