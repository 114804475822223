var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [_vm.$vuetify.breakpoint.lgAndUp ? 'try__padded__container' : 'px-4', 'py-16']
  }, [_vm._m(0), _c('v-row', {
    staticClass: "py-16",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.reviews, function (rev, i) {
    return _c('v-col', {
      key: i,
      class: [_vm.$vuetify.breakpoint.lgAndUp ? 'px-16' : ''],
      attrs: {
        "cols": 10,
        "md": 4
      }
    }, [_c('v-card', {
      staticClass: "rounded-tr-xl rounded-tl-xl rounded-br-xl pa-6"
    }, [_c('v-card-text', {
      staticClass: "black--text"
    }, [_vm._v(" " + _vm._s(rev.text) + " "), _c('div', {
      staticClass: "ratings pt-3"
    }, _vm._l(5, function (r) {
      return _c('v-icon', {
        key: `${r}${rev.reviewer.name}`,
        attrs: {
          "color": r <= rev.stars ? '#165FD8' : '#165FD880'
        }
      }, [_vm._v(" " + _vm._s(_vm.mdiStar) + " ")]);
    }), 1)])], 1), _c('v-row', {
      staticClass: "white--text my-6 py-6"
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-avatar', {
      staticClass: "circular__border",
      attrs: {
        "size": "56px"
      }
    }, [_c('v-img', {
      attrs: {
        "src": rev.image
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('div', {
      staticClass: "text-h6",
      staticStyle: {
        "color": "#165fd8"
      }
    }, [_vm._v(" " + _vm._s(rev.reviewer.name) + " ")]), _c('div', {
      staticClass: "text-body-2"
    }, [_vm._v(_vm._s(rev.reviewer.position))])])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-h3 text-center"
  }, [_vm._v(" What our customers are saying "), _c('div', {
    staticClass: "rev__title__underline"
  })]);

}]

export { render, staticRenderFns }