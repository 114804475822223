var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', [_c('v-row', {
    staticClass: "socials pt-2",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-body-1 text-center",
    attrs: {
      "align-self": "center",
      "justify": "center",
      "cols": "12",
      "md": "3"
    }
  }, [_vm._v(" Follow Us On: ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, _vm._l(_vm.socials, function (s, i) {
    return _c('v-col', {
      key: i,
      staticClass: "text-center",
      attrs: {
        "cols": 2
      }
    }, [_c('a', {
      attrs: {
        "href": s.url
      }
    }, [_c('v-avatar', {
      attrs: {
        "color": s.color,
        "size": "36px"
      }
    }, [_c('v-icon', {
      staticClass: "pa-1",
      attrs: {
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(s.icon) + " ")])], 1)], 1)]);
  }), 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-light caption"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " UNIQUE BIT TECHNOLOGIES PVT. LTD. All Rights Reserved. ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }