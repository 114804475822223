export default {
  methods: {
    scrollToId(id) {
      //   console.log('Scroll to: ', id, document.getElementById(id));
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
